import { toast } from "react-toastify";
import { useEffect } from "react";
import { deleteUserAndProfile } from "../../services/ProfileService";

interface UserDeleteConfirmationProps {
  user: string;
  isVisible: boolean;
  onClose: (deletionPromise: Promise<any>) => Promise<void>;
}

const UserDeleteConfirmation = ({ user, isVisible, onClose }: UserDeleteConfirmationProps) => {
  // Disable scrolling when modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isVisible]);

  const handleOutsideClick = (event: any) => {
    if (event.target.id === 'modalBackdrop') {
      onClose(Promise.resolve());
    }
  };

  const handleUserDeletion = async () => {
    const deletionPromise = deleteUserAndProfile(user);
    deletionPromise.then(() => {
      toast.success(`${user} has been deleted successfully!`);
    }).catch((error) => {
      toast.error('Failed to delete user.');
      console.error('Error deleting user:', error);
    });
    onClose(deletionPromise);
  };

  return (
    isVisible ? (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div
          id="modalBackdrop"
          onClick={handleOutsideClick}
          className="absolute inset-0"
        >
          <div className="absolute inset-0 bg-black opacity-30"></div>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="z-10 bg-white p-6 rounded-lg shadow-xl overflow-auto max-w-h-3xl mx-auto">
          <h1 className="text-md font-semibold">Are you sure you want to delete <b>{user}</b>?</h1>
          <div className="flex justify-end mt-4 gap-2">
            <button onClick={handleUserDeletion} className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Delete
            </button>
            <button
              onClick={() => onClose(Promise.resolve())}
              className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
              Cancel
            </button>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default UserDeleteConfirmation;

