import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createUserAndProfile } from '../../services/ProfileService';

interface UserCreateModalProps {
  isVisible: boolean;
  onClose: (promise: Promise<any>) => Promise<void>;
}

const UserCreateModal: React.FC<UserCreateModalProps> = ({ isVisible, onClose }) => {
  const [username, setUsername] = useState('');

  // Disable scrolling when modal is open
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const creationPromise = createUserAndProfile(username);
    creationPromise.then(() => toast.success('User created successfully'))
    creationPromise.catch((error) => {
      toast.error('Failed to create user');
      console.error('Failed to create user:', error);
    });
    onClose(creationPromise);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-bold mb-4">Create New User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => onClose(Promise.resolve())}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserCreateModal;