import React, { useState, useEffect } from 'react';
import { getUsers, resetPassword } from '../../services/UserService';
import ChangePassword from '../modals/ChangePassword';
import SyspromptManagementModal from '../modals/SyspromptManagementModal';
import UserCreateModal from '../modals/UserCreateModal';
import UserDeleteConfirmation from '../modals/UserDeleteConfirmation';
import Loading from '../utils/Loading';
import { toast } from 'react-toastify';
import { fetchAllProfiles } from '../../services/ProfileService';
import { ProfileSelectionDTO } from '../../types/ProfileSelectionDTO';
import { RiAddFill, RiDeleteBin6Line } from 'react-icons/ri';

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<string[]>([]);
  const [showChangePasswordModal, setShowModal] = useState<boolean>(false);
  const [showSyspromptModal, setShowSyspromptModal] = useState<boolean>(false);
  const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedProfile, setSelectedProfile] = useState<ProfileSelectionDTO | null>(null);
  const [profiles, setProfiles] = useState<ProfileSelectionDTO[]>();


  const fetchUsersData = async () => {
    try {
      const rawData: string[][] = await getUsers();
      let data = rawData.flat();
      data.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));
      setUsers(data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchProfilesData = async () => {
    try {
      const data = await fetchAllProfiles();
      setProfiles(data);
    } catch (error) {
      console.error('Failed to fetch profiles:', error);
    }
  };

  useEffect(() => {
    fetchUsersData();
    fetchProfilesData();
  }, []);

  const handleOpenModal = (username: string): void => {
    setSelectedUser(username);
    setShowModal(true);
  };

  const handleCloseChangePasswordModal = (): void => {
    setShowModal(false);
  };

  const handleResetPassword = (username: string): void => {
    resetPassword(username).then(() => {
      toast.success(`Password reset for user: ${username}`);
    }).catch((error) => {
      toast.error('Failed to reset password');
      console.error('Failed to reset password:', error);
    });
  }

  const handleCopyLink = (username: string) => {
    const loginUrl = `${window.location.origin}/login?username=${username}`;
    navigator.clipboard.writeText(loginUrl)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch(err => {
        toast.error("Failed to copy link.");
        console.error('Failed to copy text: ', err);
      });
  };

  const handleOpenSyspromptModal = (profile: ProfileSelectionDTO): void => {
    setSelectedProfile(profile);
    setShowSyspromptModal(true);
  };

  const handleCloseSyspromptModal = (): void => {
    setShowSyspromptModal(false);
    setSelectedProfile(null);
  };

  const handleOpenNewUserModal = (): void => {
    setShowNewUserModal(true);
  };

  const handleCloseNewUserModal = async (userCreationPromise: Promise<any>) => {
    setShowNewUserModal(false);
    setLoading(true);
    await userCreationPromise;
    fetchUsersData();
    fetchProfilesData();
    setLoading(false);
  };

  const handleDeleteUser = (username: string): void => {
    setSelectedUser(username);
    setShowDeleteConfirmationModal(true);
  };

  const handleCloseDeleteConfirmationModal = async (deletionPromise: Promise<any>) => {
    setShowDeleteConfirmationModal(false);
    setLoading(true);
    await deletionPromise;
    fetchUsersData();
    setLoading(false);
  };

  return (
    <div className="max-w-5xl mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden border border-teal-500">
      <div className="p-6">
        <h1 className="text-2xl font-bold text-gray-900">Users</h1>
        <ul className="space-y-1 mt-4">
          {users.map((user: string, index: number) => {
            const userProfile = profiles?.find(
              (profile) => profile.name.toLowerCase() === user.toLowerCase()
            );
            return (
              <li
                key={index}
                className="flex font-bold justify-between items-center bg-white p-4 shadow-sm rounded-md border border-gray-300"
              >
                <span className="font-bold text-gray-700">
                  {user} {userProfile?.saved_name && `- ${userProfile.saved_name}`}
                </span>

                <div className="flex flex-wrap gap-2">
                  <button
                    onClick={() => handleCopyLink(user)}
                    className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                  >
                    Copy Login Link
                  </button>

                  <button
                    onClick={() => handleOpenModal(user)}
                    className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                  >
                    Change Password
                  </button>

                  <button
                    onClick={() => handleResetPassword(user)}
                    className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                  >
                    Reset Password
                  </button>

                  {userProfile && (
                    <button
                      onClick={() => handleOpenSyspromptModal(userProfile)}
                      className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                    >
                      Sysprompt Management
                    </button>
                  )}
                  <div className="inline-flex items-center">
                    <RiDeleteBin6Line
                      onClick={() => handleDeleteUser(user)}
                      className="text-red-500 hover:text-red-700 cursor-pointer"
                    />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        <div className="flex font-bold justify-between items-center bg-white p-4 shadow-sm">
          <button
            onClick={handleOpenNewUserModal}
            className="inline-flex items-center px-5 py-3 text-sm bg-green-500 hover:bg-green-700 text-white rounded-md transition-colors"
          >
            <RiAddFill className="h-5 w-5 mr-2" />
            New User
          </button>
        </div>
      </div>
      {loading && <Loading />}
      {showChangePasswordModal && (
        <ChangePassword
          selectedUser={selectedUser}
          isVisible={showChangePasswordModal}
          onClose={handleCloseChangePasswordModal}
        />
      )}
      {showSyspromptModal && selectedProfile && (
        <SyspromptManagementModal
          profile={selectedProfile}
          isVisible={showSyspromptModal}
          onClose={handleCloseSyspromptModal}
        />
      )}
      {showNewUserModal && (
        <UserCreateModal
          isVisible={showNewUserModal}
          onClose={handleCloseNewUserModal}
        />
      )}
      {showDeleteConfirmationModal && (
        <UserDeleteConfirmation
          user={selectedUser}
          isVisible={showDeleteConfirmationModal}
          onClose={handleCloseDeleteConfirmationModal}
        />
      )}
    </div>
  );
};


export default AdminPanel;
